<script setup>
  import { ref } from 'vue';
  import { Link } from '@inertiajs/vue3';
  import Logo from '@/Components/Logo.vue';
  import NavbarToggleButton from './NavbarToggleButton.vue';
  import SearchBar from '@/Components/SearchBar.vue';

  const showMobileNav = ref(false);
  const toggleNavbar = () => {
    showMobileNav.value = !showMobileNav.value;
  };
</script>

<template>
  <div class="flex h-20 w-full flex-row items-center bg-white py-6 text-white">
    <div class="w-1/3">
      <div class="mx-auto w-2/3">
        <a href="/">
          <Logo />
        </a>
      </div>
    </div>

    <div class="w-1/3">
      <div class="flex w-full"></div>
    </div>

    <div class="flex w-1/3 flex-row">
      <div
        class="ml-6 hidden w-full flex-row text-black md:flex md:flex-row"
      ></div>
      <div class="ml-20 mt-2 justify-end md:hidden"></div>
    </div>
  </div>
</template>
